import { useEffect, useState, useCallback } from 'react';
import { Popover } from '@arco-design/web-react';

import styles from './index.module.less';
import useTuriLottie from './useTuriLottie';
import { getIsRemoveBandage } from '../EditableEmbed/EmbedScript/handle';
import Loading from '@/assets/icons/Loading.svg?react';

function AIChatBox() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isHoveredTuri, setIsHoveredTuri] = useState(false);
  const [isPressedTuri, setIsPressedTuri] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const { TuriView } = useTuriLottie({ isHovered: isHoveredTuri, isPressed: isPressedTuri });
  const isRemoveBandage = getIsRemoveBandage();
  const turi = window.turi;

  if (turi?.botStatus !== 1) return null;

  const getTuriButtonStyle = () => ({
    bottom: isRemoveBandage ? '12px' : '60px'
  });

  useEffect(() => {
    return () => {
      setIsHoveredTuri(false);
      setIsPressedTuri(false);
    };
  }, []);

  useEffect(() => {
    isPopupVisible && setIsHoveredTuri(true);
  }, [isPopupVisible]);

  const handleMouseEnter = useCallback(() => {
    !isPopupVisible && setIsHoveredTuri(true);
  }, [isPopupVisible]);

  const handleMouseLeave = useCallback(() => {
    !isPopupVisible && setIsHoveredTuri(false);
  }, [isPopupVisible]);

  const renderAIBoxFrame = useCallback(() => (
    <div className={styles['AIChatBox-container']}>
      {iframeLoading && (
        <div className={styles['AIChatBox-loading']}>
          <Loading fontSize={16} className={styles.rotate} />
        </div>
      )}
      <iframe
        className={styles['AIChatBox-iframe']}
        src={turi?.botUrl}
        allow="microphone"
        onLoad={() => setIframeLoading(false)}
      />
    </div>
  ), [turi?.botUrl, iframeLoading]);

  return (
    <Popover
      className={styles['AIChatBox-popover']}
      content={renderAIBoxFrame()}
      trigger="click"
      position="top"
      triggerProps={{ showArrow: false, popupAlign: { top: 20 } }}
      popupVisible={isPopupVisible}
      style={{ left: 'calc(100% - 424px)', maxWidth: '400px' }}
    >
      <div
        className={styles['turi-button']}
        style={getTuriButtonStyle()}
        onClick={() => setIsPopupVisible(!isPopupVisible)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={() => setIsPressedTuri(true)}
        onMouseUp={() => setIsPressedTuri(false)}
      >
        {TuriView}
      </div>
    </Popover>
  );
}

export default AIChatBox;
