import { MediaMimeType } from "@components/ui/MediaFrame/const";

/**
 * 常见文件头(16进制) 包含4 byte offset
 */
export const MEDIA_FILE_HEADER_MAP = {
  "image/jpeg": "ffd8ff",
  "image/png": "89504e47",
  "image/gif": "47494638",
  "image/webp": "52494646",

  "audio/ogg": "4f676753",
  "audio/aac": ["fff1", "fff9"],
  "audio/flac": "664c6143",
  // 'audio/m4a': '66747970',
  "audio/mp3": "494433",
  "audio/wav": "52494646",

  // 'video/avi': '52494646',
  // 'video/flv': '464c5601',
  // 'video/mkv': '1a45dfa3',
  // 'video/mov': '6d6f6f76',
  "video/mp4": "66747970",
  "video/mpeg": "000001ba",
  // 'video/webm': '1a45dfa3',
  // 'video/wmv': '3026b275',
};

const MAX_HEADER_LENGTH = 10;
/**
 * Get media file type if it's supported.
 * @param {ArrayBuffer} buffer
 * @returns {string} media file type.
 */
export function getMediaFileTypeByBuffer(buffer: ArrayBuffer): string {
  const uint8Array = new Uint8Array(buffer);

  let data = "";
  let byteOffset4Data = "";

  for (
    let i = 0;
    i < uint8Array.length && data.length < MAX_HEADER_LENGTH;
    i++
  ) {
    data += uint8Array[i].toString(16);
  }

  for (
    let i = 4;
    i < uint8Array.length && byteOffset4Data.length < MAX_HEADER_LENGTH;
    i++
  ) {
    byteOffset4Data += uint8Array[i].toString(16);
  }

  const keys = Object.keys(MEDIA_FILE_HEADER_MAP);
  for (const key of keys) {
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    const header = MEDIA_FILE_HEADER_MAP[key];
    if (Array.isArray(header)) {
      if (
        header.some((h) => data.startsWith(h) || byteOffset4Data.startsWith(h))
      ) {
        return key;
      }
    } else {
      if (data.startsWith(header) || byteOffset4Data.startsWith(header)) {
        return key;
      }
    }
  }
  throw new Error("Invalid media file or not supported");
}

export async function getMediaFileTypeByURL(url: string) {
  try {
    const res = await fetch(url);
    const type = getMediaFileTypeByBuffer(await res.arrayBuffer());
    const mimeType = type.split("/")[0];
    if (["image", "audio", "video"].includes(mimeType)) {
      return mimeType as MediaMimeType;
    }
    return "";
  } catch (err) {
    return "";
  }
}
