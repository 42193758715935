import { useEffect } from "react";
import { getIsRemoveBandage } from "./handle";

import { TURI_BUTTON_WIDTH } from '@/modules/BaseComponent/AIChatBox/const';

interface useTypeformPopoverStyleHackerProps {
  enable: boolean;
}

const TYPEFORM_POPOVER_STYLE_ID = 'dynamic-typeform-popover-style';

export default function useTypeformPopoverStyleHacker(props: useTypeformPopoverStyleHackerProps) {
  const { enable } = props;

  const isRemoveBandage = getIsRemoveBandage();
  const isShowTuri = window.turi?.botStatus === 1;

  useEffect(() => {
    if (!enable) {
      return;
    }

    const existedStyleElement = document.getElementById(TYPEFORM_POPOVER_STYLE_ID) as HTMLStyleElement;

    if (existedStyleElement) {
      document.head.removeChild(existedStyleElement);
    }

    const getBottomPosition = () => {
      const baseBottom = isRemoveBandage ? 26 : 72;
      return baseBottom + (isShowTuri ? TURI_BUTTON_WIDTH : 0);
    };

    const getPublishPosition = () => {
      const turiOffset = isShowTuri ? TURI_BUTTON_WIDTH : 0;
      const bandageOffset = isRemoveBandage ? 0 : 50;
      const totalOffset = turiOffset + bandageOffset;
      return totalOffset ? `calc(50% - ${totalOffset}px)` : '50%';
    };
  
    const newStyleElement = document.createElement('style');
    newStyleElement.id = TYPEFORM_POPOVER_STYLE_ID;
    newStyleElement.innerHTML = `
        .tf-v1-popover-button {
          right: 20px !important;
          bottom: ${getBottomPosition()}px !important;
        }

        .tf-v1-popover-wrapper {
          top: ${getPublishPosition()} !important;
        }
      `;
    document.head.appendChild(newStyleElement);
  }, [isRemoveBandage, enable]);
}
