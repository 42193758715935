import { useEffect, useMemo, useState } from "react";
import { MediaParams, MediaType } from "./const";
import queryString from "query-string";
import { getMediaFileTypeByURL } from "@/utils/file";

export function getValidBool(value: unknown): boolean {
  if (typeof value === "boolean") {
    return value;
  }
  if (typeof value === "string") {
    return value === "true";
  }
  return false;
}

export function useAdjustedParams(type: MediaType, paramString: string) {
  const parsedParams = useMemo(() => {
    const jsonParams = queryString.parse(paramString);
    return {
      ...jsonParams,
      loop: getValidBool(jsonParams.loop),
      mute: getValidBool(jsonParams.mute),
      autoplay: getValidBool(jsonParams.autoplay),
    } as Partial<MediaParams["media"]>;
  }, [paramString]);
  const [finalParams, setFinalParams] = useState(parsedParams);

  useEffect(() => {
    if (type === "media" && !parsedParams.mimeType) {
      getMediaFileTypeByURL(parsedParams.url!).then((mimeType) => {
        if (mimeType) {
          setFinalParams({ ...parsedParams, mimeType });
        }
      });
    } else {
      setFinalParams(parsedParams);
    }
  }, [type, parsedParams]);

  return finalParams as MediaParams[MediaType];
}
