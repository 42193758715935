import { memo } from 'react';

import EditableEmbed from '..';
import queryString from 'query-string';

function EmbedSidetab() {
  const attachments = window.attachments;

  const popoverAttachment = attachments?.find(
    attachment => attachment.params.displayMode === 'sidetab',
  );

  const type = popoverAttachment?.type;
  const url = popoverAttachment?.params.url;
  const buttonText = popoverAttachment?.params.buttonText;

  if (!popoverAttachment || !type) {
    return null;
  }

  const renderAttachment = () => {
    if (type === 'typeform') {
      return (
        <EditableEmbed
          type="typeform"
          params={queryString.stringify({
            url,
            displayMode: 'sidetab',
            buttonText,
          })}
          propKey='EmbedSidetab' // 标记，排查问题用，不具备实际功能
          // 覆盖掉基础样式，不在 less 文件修改，保持和 builder 工程统一
          style={{
            width: 0,
            height: 0,
            margin: 0
          }}
        />
      );
    }
    return null;
  };

  return renderAttachment();
}

export default memo(EmbedSidetab);
