import type { MediaParams, MediaType } from './const';

function getYoutubeEmbedUrlByShareUrl(url: URL) {
  const hostReg = /^(https:\/\/)?(www\.)?youtu\.be$/;
  const pathReg = /^\/([\w-]){11}$/;
  if (!hostReg.test(url.origin) || !pathReg.test(url.pathname)) {
    return "";
  }
  const videoId = url.pathname.replace("/", "");
  const params = url.searchParams.toString();
  if (!videoId) {
    return "";
  }
  return `https://youtube.com/embed/${videoId}?playlist=${videoId}&${params}`;
}

function getYoutubeEmbedUrlByBrowserUrl(url: URL) {
  const hostReg = /https:\/\/(www\.)?youtube\.com/;
  if (!hostReg.test(url.origin) || url.pathname !== "/watch") {
    return "";
  }
  const videoId = url.searchParams.get("v");
  if (!videoId) {
    return "";
  }
  return `${url.origin}/embed/${videoId}?playlist=${videoId}`;
}

const urlParseMap: {
  [K in MediaType]: (url: URL, params: MediaParams[K]) => string;
} = {
  youtube: (url, params) => {
    const shareUrl = getYoutubeEmbedUrlByShareUrl(url);
    const browserUrl = getYoutubeEmbedUrlByBrowserUrl(url);
    if (!shareUrl && !browserUrl) {
      return "";
    }
    const { autoplay = false, mute = false, loop = false } = params;
    return `${
      shareUrl || browserUrl
    }&loop=${+loop}&autoplay=${+autoplay}&mute=${+mute}}`;
  },
  vimeo: (url, params) => {
    const host = "https://vimeo.com";
    if (url.origin !== host || !/^(\/\d{9})$/.test(url.pathname)) {
      return "";
    }
    const { autoplay = false, mute = false, loop = false } = params;
    return `${host}${url.pathname}?loop=${loop}&muted=${mute}&autoplay=${autoplay}`;
  },
  spotify: (url, params) => {
    const host = "https://open.spotify.com";
    if (url.origin !== host || !/^(\/track)\/[\w-]+$/.test(url.pathname)) {
      return "";
    }
    const trackId = url.searchParams.get("si");
    if (!trackId) {
      return "";
    }

    const theme = params.theme === "light" ? 1 : 0;
    return `${host}${url.pathname}/?si=${trackId}&theme=${theme}&size=${params.size}`;
  },
  soundCloud: (url, params) => {
    const host = "https://soundcloud.com";
    if (url.origin !== host || !url.searchParams.size) {
      return "";
    }
    return `${url.href}&auto_play=${Boolean(params.autoplay)}`;
  },
  media: (url) => {
    return url.href;
  },
};

export function getValidMediaFrameUrl<T extends MediaType>(
  type: T,
  url: string,
  params: MediaParams[T]
) {
  const parsedUrl = new URL(url);
  const safeUrl = urlParseMap[type](parsedUrl, params);
  if (!safeUrl) {
    throw new Error("Invalid URL");
  }
  return safeUrl;
}

export function addSearchParams(url: string, params: Record<string, string>) {
  const urlObj = new URL(url);
  Object.entries(params).forEach(([key, value]) =>
    urlObj.searchParams.set(key, value)
  );
  return urlObj.toString();
}
