import type { LottieOptions } from 'lottie-react';
import { useLottie } from 'lottie-react';
import activeAnimation from './daijixunhuan.json';
import hoverAnimation from './hover.json';

import { TURI_BUTTON_WIDTH } from '../const';
import { LottieProps } from './const';

function useTuriLottie(props: LottieProps) {
  const { isHovered = false, isPressed = false } = props;

  const getAnimationData = () => {
    return isHovered ? hoverAnimation : activeAnimation;
  };

  const options: LottieOptions<'svg'> = {
    animationData: getAnimationData(),
    loop: !isHovered,
    autoplay: true,
  };

  const style: React.CSSProperties = {
    width: TURI_BUTTON_WIDTH,
    height: TURI_BUTTON_WIDTH,
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    ...(isPressed && {
      transition: 'all 0.1s linear',
      transform: 'scale(0.9) translateY(-1px)',
    }),
  };

  const { View } = useLottie(options, style);

  return {
    TuriView: View,
  };
}

export default useTuriLottie;
