import Intercom from '@intercom/messenger-js-sdk';
import { useEffect } from 'react';
import { getIsRemoveBandage } from './handle';
import { TURI_BUTTON_WIDTH } from '@/modules/BaseComponent/AIChatBox/const';
interface UseIntercomEmbedProps {
  app_id: string;
  type: string;
}

const INTERCOM_STYLE_ID = 'dynamic-intercom-style';

export default function useIntercomEmbed(props: UseIntercomEmbedProps) {
  const { type, app_id } = props;

  const isRemoveBandage = getIsRemoveBandage();
  const isShowTuri = window.turi?.botStatus === 1;

  useEffect(() => {
    if (!app_id) {
      return;
    }

    const existedStyleElement = document.getElementById(INTERCOM_STYLE_ID) as HTMLStyleElement;

    if (existedStyleElement) {
      document.head.removeChild(existedStyleElement);
    }

    const getAppBottomPosition = () => {
      const basePosition = isRemoveBandage ? 20 : 72;
      return basePosition + (isShowTuri ? TURI_BUTTON_WIDTH : 0);
    };

    const getFrameBottomPosition = () => {
      const basePosition = isRemoveBandage ? 84 : 134;
      return basePosition + (isShowTuri ? TURI_BUTTON_WIDTH : 0);
    };

    const newStyleElement = document.createElement('style');
    newStyleElement.id = INTERCOM_STYLE_ID;
    newStyleElement.innerHTML = `
        .intercom-lightweight-app-launcher {
          bottom: ${getAppBottomPosition()}px !important;
        }
        .intercom-messenger-frame {
          bottom: ${getFrameBottomPosition()}px !important;
        }
        .intercom-app div:nth-child(2) {
          bottom: ${getAppBottomPosition()}px !important;
        }
      `;
    document.head.appendChild(newStyleElement);
  }, [isRemoveBandage, app_id]);

  useEffect(() => {
    if (type === 'intercom' && app_id) {
      Intercom({
        app_id,
      });
    }
  }, [app_id, type]);
}
