export interface ImageExtra {
  scale: number;
  x: number;
  y: number;
};

export const ImageExtraKeys: (keyof ImageExtra)[] = ['scale', 'x', 'y'];

export const MAX_SCALE = 5;
export const IMAGE_CROPPER_MAX_WIDTH = 320 - 48;

export interface ElementRect {
  width: number;
  height: number;
}


export interface EditableImgProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  propKey: string;
}

export interface ImageExtra {
  scale: number;
  x: number;
  y: number;
};