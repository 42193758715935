import { MediaParams, MediaType } from "./const";
import { MediaFrame } from "@components/ui/MediaFrame";
import classNames from "classnames";
import styles from "./index.module.less";
import { FrameScreenShot } from "@components/FrameScreenShot";
import { useAdjustedParams } from "./handle";
interface EditableMediaProps extends React.HTMLAttributes<HTMLDivElement> {
  type: MediaType;
  params: string;
  propKey: string;
}

export default function EditableMedia(props: EditableMediaProps) {
  const { type, params, propKey, className, ...nativeProps } = props;

  const adjustedParams = useAdjustedParams(type, params);

  if (
    type === "media" &&
    (adjustedParams as MediaParams["media"]).mimeType === "image"
  ) {
    return null;
  }

  if (type !== "media" && window.__isScreenShotMode__) {
    return <FrameScreenShot type={type} name={type} />;
  }

  return (
    <div
      data-element-type="media"
      className={classNames(
        styles["editable-media-frame"],
        styles[`editable-media-frame-${type}`],
        className,
        {
          [styles[
            `editable-media-frame-${
              (adjustedParams as MediaParams["media"]).mimeType
            }`
          ]]: type === "media",
          "embed-element": type !== "media",
        }
      )}
      {...nativeProps}
    >
      <MediaFrame type={type} params={adjustedParams} />
    </div>
  );
}
